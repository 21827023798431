body {
  overflow-x: visible !important;
  overflow: clip;
  /* min-height: 100%; */
}

body > iframe {
  display: none;
}

img {
  width: 100%;
}
::-webkit-scrollbar {
  overflow: hidden;
  width: 8px;
  background: transparent;
}
::-webkit-scrollbar:horizontal {
  height: 8px;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-piece {
  background: #eee;
}
::-webkit-scrollbar-piece:start {
  background: #eee;
}
::-webkit-scrollbar-thumb {
  overflow: hidden;
  background: rgba(6, 20, 40, 0.5);
}
::-webkit-scrollbar-corner {
  overflow: hidden;
  background: rgba(6, 20, 40, 0.5);
}
/* Firefox用 */
/* スクロールバー全体のスタイル */
html {
  scrollbar-width: thin;
  scrollbar-color: rgba(6, 20, 40, 0.5) transparent;
}

.display-none {
  display: none;
}
.layout {
  display: grid;
  grid-template-columns: 256px auto;
  min-height: 100vh;
  height: 100vh;
  display: table;
  width: 100%;
}

.layout.no-menu {
  display: flex;
}
@media (max-width: 767px) {
  .layout.no-menu {
    overflow-x: hidden;
  }
}
.layout.no-menu .layout-right-top + .right-body {
  width: 100vw;
}
.layout .layout-left {
  min-width: 256px;
  width: 256px;
  max-height: 100vh;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  display: table-cell;
  vertical-align: top;
}

.layout .layout-right {
  width: auto;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  vertical-align: top;
  display: table-cell;
  vertical-align: top;
  height: 100%;
  min-width: 944px;
}

.layout.no-menu .layout-right {
  width: 100%;
}

.layout.no-menu .layout-right .layout-right-top {
  width: 100%;
}

/******************************/

.layout .layout-left .left-fixed-box {
  height: 100vh;
  width: 256px;
  border-right: 1px solid #e2e2e8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left-head-box {
  position: absolute;
  margin-top: 60px;
}

.layout .layout-left > .container {
  position: absolute;
}

.layout .layout-left .menu-container {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: space-between;
}

.from_document_select_container{
  min-height: calc(100vh - 150px) !important;
}


.layout .layout-left .menu-container .menu-box {
  padding: 20px 15px 5px;
}

.layout .layout-left .menu-bottom-container {
  padding: 20px 20px 20px;
}

.layout .layout-left .menu-bottom-container .menu-bottom {
  font-weight: 400;
  color: #626669;
  font-size: 12px;
}

.layout .layout-left .menu-bottom-container .menu-bottom .info-context {
  display: flex;
  align-items: center;
}

.layout .layout-left .logo-horizontal {
  height: 60px;
  width: 100%;
  top: 0;
  left: 0;
  min-height: 60px;
  border-bottom: 1px solid #e2e2e8;
  box-sizing: border-box;
  background-color: #fff;
}

.layout .layout-left .logo-horizontal .logo-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px 0px 0px;
}

.layout .layout-left .logo-horizontal .logo-wrapper:hover {
  background: #f2f2f8;
}

.logo-horizontal .logo-wrapper img {
  width: 60px;
}

.copylight {
  margin-top: 5px;
}

.footerInfo a {
  display: inline-block;
  padding: 2px 5px;
}

.layout .layout-right.is-drive {
  position: relative;
}

.layout .layout-right.is-drive .right-body {
  /* position: relative; */
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.signer-container,
.sender-container,
.circle-container,
.verification-container,
.evaluation-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  overflow-y: auto;
}

@media screen and (max-width: 1260px) {
  .layout .layout-right .right-body {
    overflow-x: auto;
  }
  .layout .layout-right .right-body.right_body_drive {
    overflow-x: hidden;
  }
}
@media screen and (max-width: 767px) {
  .layout .layout-right .right-body {
    width: 100vw;
  }
}
.layout .layout-right-top + .right-body {
  width: 100%;
  height: calc(100vh - 60px);
  /* height: 100vh; */
  overflow-y: auto;
  overflow-x: hidden;
}
.layout .layout-right-top + .right-body.page-setting {
  overflow-x: auto;
  height: calc(100vh - 60px);
}

body.signer .layout .layout-right-top + .right-body,
body.sender .layout .layout-right-top + .right-body,
body.circle .layout .layout-right-top + .right-body {
  height: inherit;
  overflow-y: auto;
  overflow-x: auto;
}
body.verification .layout .layout-right-top + .right-body,
body.search .layout .layout-right-top + .right-body {
  height: calc(100vh - 60px);
}

.layout .layout-right-top {
  height: 60px;
  width: 100%;
  min-height: 60px;
  border-bottom: 1px solid #e2e2e8;
  background: #ffffff;
  box-sizing: border-box;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .layout.no-menu .layout-right .layout-right-top {
    width: 100vw;
  }
}

.layout .layout-right-top .icon {
  min-width: 30px;
}

.layout .layout-right-top .icon > div {
  padding-top: 4px;
  padding-bottom: 4px;
}

.layout .layout-right-top .icon > span {
  padding: 0px 16px;
  line-height: 40px;
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tenant-switch {
  font-size: 0.81rem;
}

.layout .layout-right-top .profile-icon,
.layout .signer-list-wrapper .user-info .display-avatar {
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 5px;
  overflow: hidden;
  border-radius: 15px;
}

.layout .signer-list-wrapper .user-info .display-avatar {
  min-width: 34px;
  min-height: 34px;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  margin: 0;
}

.layout .layout-right-top .profile-icon div,
.layout .signer-list-wrapper .user-info .display-avatar div {
  background: #d8d9df;
}

.layout .layout-right-top .profile-icon svg,
.layout .signer-list-wrapper .user-info .display-avatar svg {
  color: #7b7ea6;
  width: 18px;
}

.layout .layout-right-top .top-right {
  display: flex;
  justify-content: space-between;
}

.layout .layout-right-top .profile-menu {
  border-radius: 20px;
}

.layout .layout-right-top .profile {
  display: flex;
  border-radius: 20px;
  padding: 4px 16px 4px 0px;
  border: 1px solid transparent;
  text-decoration: none;
  min-width: 200px;
  background: #e8e8ec;
  justify-content: flex-start;
}

.layout .layout-right-top .profile .profile-icon span {
  font-size: 34px;
  line-height: 30px;
  text-indent: -2px;
  color: #a2a6a9;
}

.layout .layout-right-top .profile-menu .MuiMenu-List {
  position: absolute;
  width: 100%;
  margin-top: 0px;
  border: 1px solid #e2e2e8;
  border-top: none;
  border-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #f2f2f8;
}

.error-tr {
  background: #f2f2f8;
}

.error {
  height: 100vh;
}

.error .errorPageElements {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.errorPageElementsimg {
  max-width: 450px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .errorPageElementsimg {
    max-width: 300px;
  }
}

.layout .layout-right-top .show .profile {
  border-color: #f2f2f8;
  border-bottom-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.layout .layout-right-top .profile-menu .dropdown-item:hover {
  background: #e2e2e8;
}

.layout .layout-right-top .profile-info span {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 180px;
  white-space: nowrap;
  text-align: left;
}

.layout .layout-right-top .profile-info .info-context {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.layout .layout-right-top .profile-info .name {
  font-size: 14px;
  line-height: 16px;
  height: 16px;
  font-weight: 500;
  color: #062b60;
}

.layout .layout-right-top .profile-info .tenant {
  font-size: 12px;
  height: 14px;
  line-height: 14px;
  font-weight: 500;
  color: #9398ab;
}

.layout .layout-right-top .system-notification {
  color: red;
}

.layout .layout-right .right-body .right-body-container {
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  height: 100%;
  position: relative;
}

.layout .layout-right .right-bottom .licensce-info {
  font-weight: 400;
  color: rgb(147, 152, 171);
  font-size: 12px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: flex-end;
}

.layout .layout-left .menu-container .menu-custom {
  padding: 0;
}

.layout .layout-left .menu-container .menu-custom li {
  cursor: pointer;
  padding: 0;
  margin-bottom: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.layout .layout-left .menu-container .menu-custom li .document-count {
  position: absolute;
  right: 15px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout .layout-left .menu-container .menu-custom li .document-count span {
  display: block;
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  text-align: right;
  line-height: 22px;
  font-size: 0.8rem;
  font-weight: 500;
}

.layout
  .layout-left
  .menu-container
  .menu-custom
  li
  .document-count
  span.is-zero {
  display: none;
}

.layout .layout-left .menu-container .menu-custom li .avatar-custom {
  display: flex;
  align-items: center;
}

.layout
  .layout-left
  .menu-container
  .menu-custom
  li:not(.active)
  .avatar-custom {
  color: #062b60;
}

.layout
  .layout-left
  .menu-container
  .menu-custom
  li
  .avatar-custom
  .icon-avatar,
.layout
  .layout-left
  .menu-container
  .menu-custom
  li
  .avatar-custom
  .text-avatar {
  height: 48px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.layout
  .layout-left
  .menu-container
  .menu-custom
  li
  .avatar-custom
  .icon-avatar
  svg {
  height: 24px;
  width: 24px;
}

.layout
  .layout-left
  .menu-container
  .menu-custom
  li.active
  .avatar-custom
  .icon-avatar
  svg {
  filter: none;
}

.layout
  .layout-left
  .menu-container
  .menu-custom
  li
  .avatar-custom
  .text-avatar
  span {
  font-size: 1rem;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-left: 12px;
}

.layout .layout-left .menu-container .menu-custom li a {
  display: block;
  width: 100%;
  height: 40px;
  border-radius: 999px;
  background-color: #ffffff;
  padding: 0 13px;
}

.layout .layout-left .menu-container .menu-custom li a:hover {
  background-color: #f2f2f8;
}

.layout .layout-left .menu-container .menu-custom li.active a {
  background-color: #e8e8ec;
}

.layout .layout-left .menu-container .menu-custom li.active a {
  font-weight: 600;
  color: #062b60;
}

.layout .layout-left .menu-container .step-custom li {
  padding: 8px 10px;
  margin: 6px 0;
  display: flex;
  align-items: center;
}

.layout .layout-left .menu-hidden {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: -256px;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  background-color: #fff;
  /* border-right: 1px solid #cdcfd9; */
  /* box-shadow: 0px 0px 100px inset #000000bf; */
}

.layout .layout-left .menu-hidden.active {
  left: -1px;
}

.layout .container-fluid {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (max-width: 767px) {
  .layout .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

.layout .layout-right-title h2 {
  font-size: 18px;
  line-height: 24px;
  color: inherit;
  margin: 40px 0;
}

.layout .title h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: 18px 0 20px;
}

.layout .page-settings .title h2 {
  margin: 15px 0 0 0;
  padding: 0;
}

.layout .tenant-info {
  border: 1px solid #e2e2e8;
  border-radius: 6px;
  background-color: #f2f2f8;
}

.layout .tenant-info-item {
  border-bottom: solid 1px #e2e2e8;
  padding: 16px;
  display: flex;
  align-items: center;
}

.layout .tenant-info-item:last-child {
  border-bottom: none;
  border-radius: 0 0 6px 6px;
}

.layout .tenant-info-item .label {
  margin: 0;
  white-space: nowrap;
}

.layout .tenant-info-item .value .label {
  margin-right: 20px;
}

.layout .tenant-info-item .label .icon {
  width: 24px;
  height: 24px;
}

.layout .tenant-info-item > .label {
  display: inline-block;
  width: 180px;
  font-size: 0.81rem;
  color: #535963;
  font-weight: 400;
}

.layout .tenant-info-item .value {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
}

.layout .tenant-info-item div {
  margin-right: 20px;
}

.layout .tenant-info-item div span {
  padding: 0 5px 0 0;
}

.text-unit {
  font-size: 0.8em;
}

.layout .tenant-info-item .tenant-info-item-small {
  font-size: 0.9em;
}

.layout .tenant-info-item .services-label {
  white-space: nowrap;
}

.layout .tenant-info-item .services-label .icon {
  width: 24px;
  height: 24px;
}

.layout .tenant-info-item .services-label p.label {
  margin-right: 8px;
}

.layout .tenant-info-item .services-label p.label:not(:last-child)::after {
  content: ",";
}

.layout .document-info .list-stat-item {
  display: inline-block;
  width: 150px;
  border-right: solid 1px #e2e2e8;
  text-align: right;
  padding: 10px 15px 10px 0;
  margin-bottom: 20px;
}

.layout .document-info .list-stat-item p {
  margin-bottom: 0;
  color: #4c4f5e;
}

.layout .document-info .list-stat-item p.label {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.layout .document-info .list-stat-item p.label svg {
  width: 24px;
  height: 24px;
}

.layout .document-info .list-stat-item p.label span {
  padding: 0 0 0 6px;
}

.layout .document-info .list-stat-item p.value {
  font-size: 20px;
  line-height: 28px;
  margin-top: 5px;
}

.layout .header-wrapper {
  top: 0;
  left: 0;
  height: 60px;
  padding: 6px 15px 6px 5px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  z-index: 1;
}

.layout .pdf-full-preview-wrapper.active {
  position: fixed;
  z-index: 999;
  top: 60px;
  left: 255px;
  width: calc(100vw - 255px);
  height: calc(100vh - 60px);
}

@media only screen and (max-width: 767px) {
  .layout .pdf-full-preview-wrapper.active {
    top: 0 !important;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  .layout .header-wrapper.sticky.mobile-top {
    position: sticky;
    top: -120px !important;
    border-bottom: 1px solid #e2e2e8;
    z-index: 11;
  }
}
.layout .pdf-full-preview-wrapper.active .header-wrapper {
  top: -60px;
}

.layout .header-wrapper.sticky {
  position: sticky;
  border-bottom: 1px solid #e2e2e8;
  z-index: 11;
}

.layout .evaluation-wrapper .header-wrapper.sticky {
  padding-top: 10px;
  padding-bottom: 10px;
  width: calc(100vw - 256px) !important;
}

.layout .header-wrapper .header-left {
  display: flex;
}

.layout .header-wrapper .header-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout .header-wrapper .header-right,
.layout .header-wrapper .header-left {
  display: flex;
  align-items: center;
  font-size: 14px;
  flex-shrink: 0;
}

.layout .header-wrapper.above .header-left {
  margin-top: 8px;
}

.layout .document-detail.active .pdf-full-preview-wrapper .header-wrapper {
  padding-right: 15px;
}

/* .layout .document-detail.active .header-wrapper .header-right div {
    margin-right: 0px;
} */

.layout .header-center.document-title {
  display: flex;
  align-items: center;
}
.layout .attachment-icon {
  width: 20px !important;
}

.layout .header-wrapper .document-title {
  font-size: 1.1rem;
  line-height: 45px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  display: block;
  padding: 0 20px;
  width: calc(100vw - 670px);
}

.layout .btn.btn-custom {
  /* box-shadow: 0 1px 6px rgb(0 0 0 / 26%);
  border-radius: 100px; */
  /* padding: 5px 15px; */
  /* color: #1976d2; */
  border: 1px solid rgba(25, 118, 210, 0.5);
  min-width: inherit;
}

.layout .btn-custom-verification {
  box-shadow: 0 1px 6px rgb(0 0 0 / 26%);
  border-radius: 100px;
  padding: 6px 10px;
}

.layout .btn.btn-custom span {
  left: 8px;
}

.layout .btn-custom .label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.layout .header-wrapper .pagination {
  display: flex;
  align-items: center;
}

.layout .body-wrapper .item-row {
  padding: 0;
}
.layout .is-sign .body-wrapper {
  height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .layout .is-sign .body-wrapper {
    overflow-x: auto;
  }
}

/* .layout .layout-right-top + .right-body .body-wrapper {
  height: calc(100% - 118px);
  overflow: auto;
} */

/* .layout .body-wrapper-scroll.fixed {
  overflow: auto;
  width: calc(100vw - 240px);
} */

.resizable-container {
  width: calc(100vw - 256px);
}

.layout #tableId {
  min-width: 1300px !important;
  width: 100% !important;
  overflow: clip !important;
}
.layout #tableId th,
.layout #tableId td {
  white-space: nowrap;
}

.layout .btn-pagination {
  background: transparent;
  border-radius: 22px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  border: none;
}

.layout .btn-pagination:hover {
  background: #f2f2f8;
}
.step-custom {
  padding-left: 10px;
}
.step-custom .step-child {
  position: relative;
  display: flex;
  align-items: center;
  color: #cecfd9;
}

.step-custom .step-child .step-circle {
  position: relative;
  min-width: 15px;
  height: 15px;
  border-radius: 50px;
  border: 2px solid #dadada;
  margin-right: 16px;
  background-color: #ffffff;
}

.step-custom.step-vertical .step-child .step-access-ramp {
  border-left: 3px solid #cecfd9;
  position: absolute;
  height: 40px;
  top: 13px;
  left: 4px;
}

.step-custom.step-vertical .step-child:last-child .step-access-ramp {
  display: none;
}

.step-custom .step-child .step-circle .step-circle-dot {
  background-color: transparent;
  width: 7px;
  height: 7px;
  border-radius: 50px;
  position: absolute;
  left: 2px;
  top: 2px;
}

.step-custom .step-child:not(.disabled) {
  color: #3697e1;
}

.step-custom .step-child.active {
  font-weight: bold;
}

.step-custom .step-child:not(.active):not(.disabled) {
  cursor: pointer;
}

.step-custom .step-child.disabled {
  cursor: not-allowed;
}

.step-custom .step-child:not(.disabled) .step-circle {
  border-color: #3697e1;
  background-color: #ffffff;
}

.step-custom .step-child.pass .step-circle .step-circle-dot {
  background-color: #3697e1;
}

.step-custom .step-child.active .step-circle .step-circle-dot {
  background-color: #ff69b4;
}

.step-custom .step-child .step-label {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 0.875rem;
}

.step-custom.step-vertical .step-child.pass .step-access-ramp {
  border-color: #3697e1;
}

.layout .layout-right .right-body .page-action-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
}

.layout .layout-right .right-body .page-action-bottom-relative {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.layout .layout-right .right-body .page-action-bottom-relative > button {
  margin: 0 7px;
}

.layout .layout-right .right-body .page-action-bottom-absolute {
  padding: 40px;
  position: absolute;
  bottom: 18px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.page-action-bottom-relative .wrapper-button-cancel,
.page-action-bottom-relative .wrapper-button-submit {
  margin: 0 10px;
}

.page-action-bottom-relative .wrapper-button-next-file button {
  margin: 0 10px;
  color: #ffffff;
  background-color: #1976d2;
}

.page-action-bottom-relative .wrapper-button-next-file-disable button {
  margin: 0 10px;
  color: #cacbd5;
  background-color: rgba(0, 0, 0, 0.12);
}

.invalid-feedback {
  font-size: 0.71rem;
}

.form-group {
  margin-bottom: 0.7rem;
}

.layout .btn:not(.input-group-child) {
  /* position: relative;
  min-width: 120px;
  height: 46px;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  margin-right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px; */
}

.layout .btn:not(.input-group-child):last-child {
  margin-right: 0;
}
.btn-primary {
  font-size: 0.875rem;
  font-weight: 500;
  min-height: 36px;
  background-color: #1976d2 !important;
  /* border-color: #1976d2 !important; */
  letter-spacing: 0.00938em;
}
.btn-primary:hover {
  background-color: #0a63bc !important;
}
.btn-secondary {
  background-color: #6c757d !important;
}
.btn-secondary:hover {
  background-color: #5a6268 !important;
}
.btn-primary .btn-label {
  color: #ffffff;
}
.layout .btn-custom-group {
  /* box-shadow: 0 1px 6px rgb(0 0 0 / 26%); */
  /* border-radius: 100px; */
  /* height: 46px; */
  margin-right: 8px;
  border-radius: 4px;
}

.layout .btn-custom-mui {
  height: unset;
  margin-left: 8px;
  margin-right: 0;

  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.layout .btn-custom-mui .btn {
  /* height: unset;
  background-color: #1976d2;
  border-left: none;
  border-right: none; */
}

.layout .btn-custom-mui .btn:hover,
.layout .btn-custom-group.btn-custom-mui .dropdown-item:hover {
  background-color: #0a63bc;
  /* box-shadow: 0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024,
    0px 1px 10px 0px #0000001f; */
}

.layout .btn-custom-mui.btn-custom-group > .btn {
  /* min-width: unset;
  white-space: nowrap;
  font-size: 0.875rem;
  border: none;
  letter-spacing: 0; */
}
.layout .signer-container .btn-custom-mui.btn-custom-group > .btn-primary,
.layout .sender-container .btn-custom-mui.btn-custom-group > .btn-primary,
.layout
  .verification-container
  .btn-custom-mui.btn-custom-group
  > .btn-primary {
  /* padding: 0 10px 0 16px; */
  padding-left: 16px;
  padding-right: 16px;
}
.layout
  .signer-container
  .btn-custom-mui.btn-custom-group
  > .btn-primary.btn-doropdown-arrow,
.layout
  .sender-container
  .btn-custom-mui.btn-custom-group
  > .btn-primary.btn-doropdown-arrow,
.layout
  .verification-container
  .btn-custom-mui.btn-custom-group
  > .btn-primary.btn-doropdown-arrow {
  padding-left: 8px;
  padding-right: 8px;
  border-left: 1px solid #1565c0;
}

.layout
  .signer-container
  .btn-custom-mui.btn-custom-group
  > .btn-primary.dropdown-toggle,
.layout
  .sender-container
  .btn-custom-mui.btn-custom-group
  > .btn-primary.dropdown-toggle {
  /* padding: 0 16px 0 10px; */
}
.layout .signer-container .btn-custom-mui.btn-custom-group > .btn,
.layout .sender-container .btn-custom-mui.btn-custom-group > .btn,
.layout .verification-container .btn-custom-mui.btn-custom-group > .btn {
  /* padding: 0 16px 0 10px; */
  /*width: 130px;*/
  /* justify-content: center; */
  display: flex;
  align-items: center;
}
.verification-identifier input#virtualize-demo {
  font-size: 0.875rem;
}
.MuiAutocomplete-popper,
.MuiAutocomplete-popper * {
  font-size: 0.875rem !important;
}

.layout .btn-custom-mui.btn-custom-group > .dropdown-toggle {
  padding: 6px 16px 6px 10px;
}

.layout .btn-custom-group > .btn {
  /* border-radius: 100px;
  padding: 6px 10px 6px 20px;
  margin: 0;
  min-width: 110px; */
}

.layout .btn-custom-group .dropdown-toggle {
  min-width: unset;
  padding: 6px 20px 6px 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.layout .btn-custom-group .dropdown-menu {
  background-color: transparent;
  border: none;
  transform: translate(0px, 52px) !important;
  padding: 0;
}

.layout .btn-custom-group .dropdown-item {
  margin-bottom: 6px;
  background-color: #fff;
  height: 46px;
  border: 1px solid #ddd;
}

.layout .btn-custom-group .dropdown-item.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.layout .btn-custom-group.btn-custom-mui .dropdown-item {
  height: unset;
  padding: 6px 16px;
  background-color: #1976d2;
}

.layout .btn.input-group-child {
  min-width: 100px;
  height: 46px;
  font-size: 14px;
  padding: 6px 4px;
  background-color: #f2f2f8;
  border-color: #ced4da;
  color: #007bff;
}
.layout .btn-input-drive {
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: #1976d2;
  height: 40px;
  margin-right: -8px;
}

.layout .btn.btn-form-control {
  height: auto;
  min-height: 46px;
  padding: 4px;
  word-break: break-all;
}

.layout .btn-label {
  width: 100%;
  margin: 0 10px;
}

.layout .layout-right .right-body .document-detail-container,
.layout .layout-right .right-body .determine-container {
  position: relative;
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: calc(100vw - 255px);
}
.layout .layout-right .right-body .documentprepare-container {
  position: relative;
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 100vw;
}

.layout
  .layout-right
  .right-body
  .document-detail-container
  .document-detail-form,
.layout .layout-right .right-body .determine-container .determine-form {
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  border-right: 1px solid #e2e2e8;
}

.layout
  .layout-right
  .right-body
  .document-detail-container
  .document-detail-form
  .custom-form-outline.form-validated {
  margin-bottom: 20px;
}

.form-folder input {
  font-size: 0.85rem !important;
  height: 40px;
}

.form-folder .MuiInputBase-root {
  height: 40px !important;
}

.layout
  .layout-right
  .right-body
  .document-detail-container
  .document-detail-form
  .custom-form-outline.form-validated
  .invalid-feedback {
  margin-top: 0;
  height: 20px;
}

.layout
  .layout-right
  .right-body
  .determine-container
  .determine-form
  .tab-content {
  padding: 15px;
}
.full-preview {
  height: 100%;
  width: 100%;
  position: relative;
}

.full-preview .control-preview {
  background-color: #474e63;
  box-shadow: inset 0 0 15px rgb(52 59 69 / 80%);
  display: flex;
  flex-direction: column;
  align-items: baseline;
  overflow: auto;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
}

.full-preview .control-preview .react-pdf__Document {
  min-width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

.full-preview .control-preview .react-pdf__Document .react-pdf__message {
  height: 100%;
}

.full-preview
  .control-preview
  .react-pdf__Document
  .react-pdf__message
  .pdf-loading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-preview
  .control-preview
  .react-pdf__Document
  .react-pdf__message
  .pdf-loading
  .img-container {
  width: 80%;
  height: 150px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 2px 2px 2px #424242;
}

.full-preview .control-preview .pdf-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.full-preview .pdf-custom-page {
  display: flex;
  justify-content: center;
}

.full-preview canvas {
  margin: 0;
  box-shadow: 4px 4px 4px #444444;
}
.layout .layout-right .right-body .control-container {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 10;
}

.layout .layout-right .right-body .control-container .imprint-stamp {
  top: 20px;
  left: 20px;
  width: 150px;
  background: #021a45c9;
  position: absolute;
  box-shadow: 0 2px 6px #00000029;
  border-radius: 6px;
  padding: 20px 10px 10px;
  user-select: none;
  max-height: 100%;
  overflow: auto;
  cursor: move;
}

.layout
  .layout-right
  .right-body
  .control-container
  .imprint-stamp
  .imprint-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.layout
  .layout-right
  .right-body
  .control-container
  .imprint-stamp
  .imprint-operator {
  margin-top: 10px;
}

.layout
  .layout-right
  .right-body
  .control-container
  .imprint-stamp
  .imprint-operator
  button {
  margin-top: 5px;
  width: 100%;
  min-width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.layout
  .layout-right
  .right-body
  .control-container
  .imprint-stamp
  .imprint-operator
  button:last-child {
  background: #dfe5f4;
}

.layout
  .layout-right
  .right-body
  .control-container
  .imprint-stamp
  .imprint-operator
  button:last-child:hover {
  background: #cdd4e4;
}

.layout
  .layout-right
  .right-body
  .control-container
  .imprint-stamp
  .imprint-block {
  min-height: 40px;
  min-width: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 11px;
  border: 1px dashed #ffffff80;
  padding: 6px;
  cursor: move;
  margin: 4px;
}

.layout
  .layout-right
  .right-body
  .control-container
  .imprint-stamp
  .imprint-block.hide {
  display: none;
}

.layout
  .layout-right
  .right-body
  .control-container
  .imprint-stamp
  .imprint-block.ondrag {
  position: absolute;
  margin-top: 0px;
  background-color: #1f3153;
  box-shadow: -2px 2px 2px #939393;
}

.layout
  .layout-right
  .right-body
  .control-container
  .imprint-stamp
  .imprint-block
  .label-text {
  font-size: 11px;
  margin-left: 10px;
  text-transform: capitalize;
}

.layout .layout-right .right-body .control-preview .imprint-block {
  border: 2px solid #3697e1;
  background: #ffffffcc;
  cursor: move;
  z-index: 2;
  font-size: 11px;
  position: absolute;
  overflow: hidden;
}

.layout .layout-right .right-body .control-preview .imprint-block.group-guests {
  border: 2px solid #f15050;
}

.layout .layout-right .right-body .control-preview .imprint-block.type-text {
  width: 240px;
  height: 46px;
  border-radius: 4px;
}

.layout .layout-right .right-body .control-preview .imprint-block.type-check {
  width: 25px;
  height: 25px;
}

.layout
  .layout-right
  .right-body
  .control-preview
  .imprint-block.type-signature {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.seal-preview{
  border: 2px dashed #575555;
  position: absolute;
  z-index: 2;
  overflow: hidden;
}

.layout .layout-right .right-body .control-preview .imprint-block.not-allowed {
  cursor: not-allowed;
}

.layout .layout-right .right-body .control-preview .imprint-block svg {
  display: none;
}

.layout .layout-right .right-body .control-preview .imprint-block .label-text {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  white-space: normal;
}

.layout
  .layout-right
  .right-body
  .control-container
  .page-index-info
  .info-label,
.control-container .page-index-info .info-label,
.layout .layout-right .right-body .control-container .btn,
.control-container .btn,
.layout .layout-right .right-body .control-preview .imprint-block,
.layout .layout-right .right-body .control-container .imprint-stamp,
.layout .layout-right .right-body .control-container button {
  pointer-events: all;
}

.layout .layout-right .right-body .control-container .view-size-controller,
.control-container .view-size-controller {
  position: absolute;
  display: flex;
  width: 90px;
  justify-content: space-between;
  right: 100px;
  top: 10px;
}

.layout .layout-right .right-body .imprint-block.group-guests svg {
  fill: #f15050;
}

.layout .layout-right .right-body .control-container .page-index-info,
.control-container .page-index-info {
  position: absolute;
  width: 100%;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout
  .layout-right
  .right-body
  .control-container
  .page-index-info
  .info-label,
.control-container .page-index-info .info-label {
  background-color: #1f232d;
  border-radius: 4px;
  padding: 4px 10px 5px;
  color: #e3e3fc;
  opacity: 0.85;
  font-size: 0.75rem;
  line-height: 0.85rem;
}

.layout .layout-right .right-body .view-note-container {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 11;
}

.view-note-container .view-note {
  position: absolute;
  width: 100%;
  bottom: 70px;
  right: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.view-note-container .view-note .info-label {
  /* background-color: #757b8d; */
  border: 1px solid #fff;
  background-color: #535a70;
  border-radius: 4px;
  padding: 10px 64px;
  color: #fff;
  opacity: 0.85;
  /* font-size: 0.75rem;
  line-height: 0.85rem; */
}

.document-view-status {
  position: fixed;
  bottom: 70px;
  left: calc((100% - 200px) / 2);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 19;
  background-color: #fff;
  box-shadow: 0 1px 5px rgb(44, 50, 59, 0.85);
  cursor: pointer;
  flex-direction: column;
}

.document-view-status .callout {
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 10px;
  width: 250px;
  background: #fff;
  padding: 12px 18px;
  display: none;
  filter: drop-shadow(0 1px 5px rgb(44, 50, 59, 0.5));
}

.document-view-status .callout-confirmed {
  top: 50%;
  transform: translateY(-50%);
}

.document-view-status .callout::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -8px;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  transform: translateY(-50%);
}

.document-view-status:hover .callout {
  display: block;
}

.document-view-status .callout .callout-title {
  color: #1e3666;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.document-view-status .callout .view-count {
  color: #535962;
  margin-bottom: 0;
  font-size: 14px;
}

.document-view-status .checker-icon {
  width: 40px;
}

.document-view-status .viewed-number-status {
  display: flex;
  flex-direction: row;
  font-size: 15px;
}

.document-view-status .viewed-number-status .viewed-number.viewed {
  color: #17a845;
  font-weight: 600;
}

.document-view-status .viewed-number-status .viewed-number.not-viewed {
  color: #f27200;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .document-view-status {
    bottom: 90px;
    left: 50%;
    transform: translateX(-50%);
  }
  .document-view-status .callout {
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.layout .btn-custom-disabled {
  color: #ffffff;
  background-color: rgba(25, 118, 210, 0.12);
}

.modal-confirm-view {
  display: flex !important;
  align-items: center;
}

.confirm-view-wrapper {
  background-color: #fff;
  color: #484e52;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  transform: translateY(-50%);
  z-index: 10;
}
@media only screen and (max-width: 575px) {
  .confirm-view-wrapper {
    transform: translateY(0%) !important;
    bottom: 5px !important;
    left: 5px !important;
    right: 5px !important;
    position: fixed;
  }
}

.confirm-view-wrapper .title-confirm-view {
  font-size: 1rem;
}

.confirm-view-wrapper .body-confirm-view {
  padding: 0;
  font-size: 0.8rem;
}
.confirm-view-wrapper .body-confirm-view {
  padding: 0;
  font-size: 0.8rem;
}
.confirm-view-wrapper .confirm-info-label {
  margin-left: 5px;
}
.confirm-view-wrapper .btn-confirm-view {
  display: flex;
  width: 150px;
  justify-content: center;
  align-self: center;
  margin-top: 16px;
  margin-bottom: 0;
}

.btn-confirm-view {
  display: flex;
  width: 150px;
  justify-content: center;
  align-self: center;
  margin-bottom: 16px;
}

.header-confirm-view {
  border-bottom-width: 0;
}

.title-confirm-view {
  font-weight: 600;
}

.body-confirm-view {
  display: flex;
  flex-direction: row;
  padding: 8px 20px 16px;
}

.confirm-info-label-name {
  font-weight: 500;
}

.layout .layout-right .right-body .control-container .btn.size-decrease,
.control-container .btn.size-decrease,
.layout .layout-right .right-body .control-container .btn.size-increase,
.control-container .btn.size-increase,
.pdf-fragment .pdf-file-info .btn-info {
  border-radius: 22px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  cursor: pointer;
  background-color: #5b647e;
  box-shadow: 0 1px 5px rgba(44, 50, 59, 0.85);
  color: #d5d5e6;
}

.layout .layout-right .right-body .control-container .btn.size-decrease:hover,
.layout .layout-right .right-body .control-container .btn.size-increase:hover,
.pdf-fragment .pdf-file-info .btn-info:hover {
  background-color: #616a85;
  box-shadow: 0 3px 10px rgb(44, 50, 59);
  color: #d7d7e8;
}

.layout .layout-right .right-body .file-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.layout .layout-right .right-body .file-panel.preview {
  position: relative;
  /* border-left: 1px solid #e2e2e8; */
}

.layout .layout-right .right-body .file-panel .file-drop-zone {
  padding-top: 50px;
}

.layout .layout-right .right-body .file-panel .file-preview-zone {
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #e2e2e8;
  align-items: center;
  justify-content: flex-start;
}
.h-60 {
  height: 60%;
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-preview-zone
  .pdf-custom-page {
  display: none;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-preview-zone
  .pdf-custom-page.active {
  display: flex;
  /* max-width: 240px; */
  /* max-height: 340px; */
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-preview-zone
  .pdf-custom-page.active
  canvas {
  box-shadow: 0 0 13px rgba(101, 111, 127, 0.3);
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-preview-zone
  .page-navigator {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-preview-zone
  .page-navigator
  .btn-icon {
  width: 26px;
  height: 26px;
  color: #484e52;
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-preview-zone
  .page-navigator
  .info-label {
  padding: 0 10px;
  font-size: 0.75rem;
  color: #484e52;
}

.nav-tabs .nav-link {
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0 1rem;
  font-size: 0.875rem;
}

.layout .layout-right .right-body .file-panel .file-drop-zone .file-dropbox,
.pdf-fragment .file-upload-panel .dropbox-area {
  height: 140px;
  width: 110px;
  margin: 0;
  cursor: pointer;
  border: 1px dashed #7a85a3;
  background-color: #656f8a;
  background-repeat: no-repeat;
  background-position: center;
}

.layout .layout-right .right-body .file-panel .file-drop-zone.nofile {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  height: 100%;
}
.attachment-wrapper {
  height: 40%;
  /* border-left: 1px solid #e2e2e8; */
  /* max-width: 240px; */
}
.attachment-upload-preview {
  border-top: solid 1px #e2e2e8;
  margin-bottom: 0;
  padding: 10px;
}
.attachment-upload-preview .attachment-upload-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  /* border: 1px solid #d5d5e6; */
  color: #535963;
  border-radius: 15px;
}
.attachment-upload-preview .attachment-upload-box img {
  width: 20px;
}
.attachment-upload-preview .attachment-upload-box span {
  font-size: 14px;
}

.attachment-upload {
  border-top: solid 1px #2c2e3f;
  background: #545d75;
  margin-bottom: 0;
  padding: 10px;
}
.attachment-upload .attachment-upload-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  border: 1px solid #d5d5e6;
  border-radius: 15px;
}
.attachment-upload .attachment-upload-box:hover {
  border: 1px solid #00d0ff;
  transition: 0.2s;
  cursor: pointer;
}
.attachment-upload .attachment-upload-box img {
  width: 20px;
}
.attachment-upload .attachment-upload-box span {
  color: #d5d5e6;
  font-size: 14px;
}
.attachment-item-preview {
  color: #535963;
  font-size: 14px;
  padding-left: 20px;
  /* min-height: 36px; */
  padding-top: 6px;
  padding-bottom: 6px;
}
.attachment-item-preview:hover {
  background-color: #f2f2f8;
}
.attachment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #d5d5e6;
  font-size: 14px;
  padding-left: 20px;
  min-height: 36px;
}
.attachment-item .file-name {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  text-overflow: ellipsis;
  display: block;
  cursor: pointer;
}
.attachment-item-preview .file-name {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  cursor: pointer;
}
.attachment-item:hover {
  color: #00d0ff;
  font-weight: 500;
}

.attachment-item:hover .file-remove {
  display: flex;
}

.attachment-item .file-remove {
  margin: 0;
  color: #ff762c;
  width: 36px;
  height: 36px;
  padding: 10px;
  font-size: 16px;
  line-height: 16px;
  display: none;
}
.attachment-item .file-remove:hover {
  background-color: #41485c;
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-drop-zone.nofile
  .file-dropbox,
.pdf-fragment .file-upload-panel .dropbox-area {
  height: 297px;
  width: 210px;
  background-size: 100px;
  transition: all 0.2s;
}

.layout .layout-right .right-body .file-panel .file-upload-list {
  height: 100%;
  /* max-height: 250px; */
  overflow-y: auto;
  padding: 15px 10px;
}
.layout .layout-right .right-body .file-panel .file-attachment-list {
  height: 100%;
  padding: 15px 10px;
  background: #545d75;
  overflow-y: auto;
  max-height: 500px;
}
.file-attachment-list-preview {
  height: 100%;
  max-height: 500px;
  padding: 0 10px;
  overflow-y: auto;
}

.layout .layout-right .right-body .file-panel .file-upload-list ul {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.layout .layout-right .right-body .file-panel .file-upload-list .file-item {
  font-size: 14px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 34px;
  color: inherit;
  cursor: pointer;
  font-weight: 400;
  padding: 0;
  border-radius: 2px;
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-upload-list
  .file-item:not(.active):hover {
  background: #f2f2f8;
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-upload-list
  .file-item.active {
  cursor: unset;
  color: #1069d5;
  font-weight: 500;
}

.layout .layout-right .right-body .file-panel .file-upload-list .file-name {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.layout .file-panel .file-upload-list .file-name {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-upload-list
  .view-status-icon {
  width: 20px;
}

.layout .layout-right .right-body .file-panel .file-upload-list .file-remove {
  margin: 0;
  color: #ff762c;
  width: 36px;
  height: 36px;
  padding: 10px;
  font-size: 16px;
  line-height: 16px;
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-upload-list
  .file-remove:hover {
  background-color: #41485c;
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-upload-list
  .file-form-validate {
  display: none;
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-upload-list
  .file-form-validate-fail {
  margin: 0 10px;
  color: #f15050;
  padding: 8px;
  font-size: 34px;
}

.layout
  .layout-right
  .right-body
  .file-panel
  .file-upload-list
  .file-form-validate-success {
  margin: 0 10px;
  color: #28a745;
  padding: 8px;
  font-size: 34px;
}

.layout .layout-right .right-body .file-panel .file-attachments {
  height: auto;
}

.layout .layout-right .right-body .file-panel .file-dropbox:hover {
  border-color: #40a9ff;
}

.layout .layout-right .right-body .file-panel .info,
.pdf-fragment .no-file .file-upload-panel label.info {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  margin: 10px;
  color: #d5d5e6;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.layout input:required,
.layout select:required {
  border-color: #da6517;
}

.layout input.is-valid,
.was-validated .form-control:valid,
.layout select.is-valid {
  border-color: #28a745;
  prepend: 1.5em;
  background-position: right calc(0.375em + 0.75rem) center;
}

.layout input.is-invalid,
.was-validated .form-control:invalid,
.layout select.is-invalid {
  border-color: #dc3545 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

.layout .layout-right .right-body .file-panel .file-dropbox input[type="file"] {
  display: none;
}

.layout .attributes-wrapper .form-group {
  height: 60px;
}

.layout .attributes-wrapper .btn-icon.remove {
  width: 46px;
  height: 46px;
}

.layout .attributes-wrapper .input-group .form-control {
  border-radius: 0.25rem;
}

.layout .attributes-wrapper .btn.attributes {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: #1976d2;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
}
.layout .attributes-wrapper .btn.attributes:hover {
  background-color: rgba(25, 118, 210, 0.04);
}

.layout .host-setting-container {
  display: flex;
  height: 100%;
}

.layout .host-setting-container .user-list {
  width: 640px;
  border-left: 1px solid #e2e2e8;
}

.layout .full-form {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.layout .full-form .search-text-field {
  width: 100%;
  font-size: 14px;
  background-color: #efeef6;
  border-radius: 10px;
  height: 40px;
}

.layout .full-form .form-body {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.layout #form_Search.full-form .form-body {
  width: 100%;
  height: unset;
  max-width: 800px;
}

.layout #form_Search.full-form .search-category-heading {
  font-size: 1em;
  margin-bottom: 15px;
}

.layout #form_Search.full-form .search-section {
  margin-bottom: 50px;
}

.layout #form_Search.full-form .from-to > .form-group .input-group {
  position: relative;
  display: flex;
  align-items: center;
}
.layout
  #form_Search.full-form
  .from-to
  > .form-group:first-child
  .input-group::after {
  position: absolute;
  top: 0.5em;
  right: -0.3em;
  content: "〜";
}

.layout .documentinfo-container .full-form .form-body {
  max-width: 700px;
}
.label.adoption {
  color: #1976d2;
}
.adoption-add-icon .material-icons {
  color: #1976d2;
}

.layout .signer-list-wrapper {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.layout .determine-container .signer-list-wrapper {
  flex-direction: row;
}

.layout .determine-container .signer-list-wrapper .form-group {
  height: 46px;
  display: flex;
}

.layout .determine-container .doc-info .form-group {
  height: 46px;
}

.layout .determine-container .doc-info .info-label {
  display: flex;
  align-items: center;
}

.user-selection-container .form-label,
.signer-list-wrapper .form-label {
  margin-bottom: 5px;
}

.search-input-container {
  display: flex;
  width: 100%;
  justify-content: center;
  max-height: 40px;
  border: 0;
}

.search-input-field {
  width: 95%;
  background-color: rgb(239 238 246);
  border: 0px;
  border-radius: 5px;
}

.search-round-container {
  display: flex;
  width: 75%;
}
.header-search-close {
  cursor: pointer;
}
.search-round {
  background-color: #e8e8ec;
  width: 80%;
  outline: none;
  transition: all 0.2s;
}
.search-round:focus-within {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(44, 50, 59, 0.5);
}

.search-round:hover {
  outline: none;
}

.layout .signer-list-wrapper .user-info {
  margin: 0;
  padding: 6px;
  width: 100%;
  min-width: 100%;
  height: 46px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  background-color: #f2f2f8;
  justify-content: space-between;
  margin: 0 0 10px 0;
  /* box-shadow: 1px 1px 2px #888; */
}

.layout .signer-list-wrapper .signature-container {
  margin-top: 40px;
}
.layout .signer-list-wrapper .signature-container .info-label.form-group {
  max-height: inherit;
  min-height: inherit;
  padding: 0;
}
.layout .signer-list-wrapper .signature-info-header {
  padding: 6px 12px;
  width: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  background-color: #052d7a;
  margin: 0 0 3px 0;
  border-radius: 0.25rem;
}

.layout .signer-list-wrapper .signature-info-title {
  min-width: 240px;
  font-size: 0.81rem;
  font-weight: 700;
  color: #ffffff;
}

.layout .signer-list-wrapper .signature-info-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: #7bbae9;
  padding: 5px 10px;
  cursor: pointer;
}

.layout .signer-list-wrapper .signature-info-item.clicked {
  background-color: #ffffff;
  flex-direction: column;
  padding: 6px;
}

.layout .signer-list-wrapper .signature-info-item.clicked .signature-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.layout .signer-list-wrapper .signature-info-item.clicked .signature-info-left {
  width: 27%;
  min-width: 95px;
  overflow: hidden;
}

.layout
  .signer-list-wrapper
  .signature-info-item.clicked
  .signature-info-right {
  flex-wrap: wrap;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.layout
  .signer-list-wrapper
  .signature-info-item.clicked
  .signature-info-right {
  text-align: left;
}

.layout .signer-list-wrapper .signature-item-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 0 3px 0;
  align-items: stretch;
  border-radius: 0.25rem;
  overflow: hidden;
}

.layout .signer-list-wrapper .signature-item-container .signature-icon {
  display: flex;
  width: 45px;
  min-width: 45px;
  background-color: #052d7a;
  align-items: center;
  justify-content: center;
}

.layout .signer-list-wrapper .signature-item-container .timestamp-info-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: #b5d3f2;
  padding: 5px 10px;
}

.layout .signer-list-wrapper .signature-info-left {
  font-size: 0.71rem;
  font-weight: 500;
  color: #1b3364;
  width: 50%;
  overflow: hidden;
  word-wrap: break-word;
}

.layout .signer-list-wrapper .signature-info-right {
  font-size: 0.71rem;
  font-weight: 500;
  color: #1b3364;
  text-align: right;
  width: auto;
  word-wrap: break-word;
}

.layout .signer-list-wrapper .signature-info-item.empty {
  background-color: unset;
}

.layout .signer-list-wrapper .user-info.user1 {
  background-color: #e1edff;
}

.layout .signer-list-wrapper .user-info.user1 .display-avatar div {
  background-color: #bad1f5;
}

.layout .signer-list-wrapper .user-info .display-avatar svg {
  width: 20px;
}

.layout .signer-list-wrapper .user-info.empty {
  background-color: unset;
}

.layout .signer-list-wrapper .user-info .user-info-detail {
  /* width: 100%;
  max-width: calc(100% - 276px); */
  padding: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: auto;
}

.layout .signer-list-wrapper .user-info .top-info {
  line-height: 1.1rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.layout .signer-list-wrapper .user-info .bottom-info {
  line-height: 1.1rem;
  font-size: 0.81rem;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #626669;
}

.layout .signer-list-wrapper .viewer-info {
  float: left;
  display: flex;
  padding: 0 10px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 12px;
  min-height: 24px;
  font-size: 0.81rem;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: #f2f2f8;
}
.layout .signer-list-wrapper .viewer-info .viewer-avatar {
  margin-top: 2px;
}
.layout .signer-list-wrapper .viewer-info .viewer-avatar + span {
  flex: 1;
  margin: 2px 0;
}

.layout .signer-list-wrapper .viewer-info.group {
  background-color: #e2e2e8;
}

.layout .user-selection-container .user-info .user-info-optional,
.layout .signer-list-wrapper .user-info .user-info-optional {
  /* width: 40px;
  min-width: 40px;
  position: sticky; */
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
  height: 100%;
}

.layout .signer-list-wrapper .user-info .user-info-optional .custom-switch {
  width: 100%;
  padding-left: 44px;
}

.layout .signer-list-wrapper .user-info .btn-icon {
  color: red;
  height: 30px;
  width: 30px;
  margin-right: 6px;
}

.layout .signer-list-wrapper .user-info .btn-icon:hover {
  background-color: #cecece;
}

.layout .signer-list-wrapper .user-info .btn-icon-bell {
  color: #626669;
  height: 30px;
  width: 30px;
  margin-right: 6px;
}

.layout .signer-list-wrapper .user-info .btn-icon-bell-active {
  color: #007bff;
  height: 30px;
  width: 30px;
  margin-right: 6px;
}

.layout .signer-list-wrapper .user-info .btn-icon-bell:hover {
  background-color: #cecece;
}

.layout .signer-list-wrapper .user-info .btn-icon svg {
  fill: red;
}

.layout .flex-column {
  display: flex;
  flex-direction: column;
  position: relative;
}

.layout .flex-right {
  display: flex;
  justify-content: flex-end;
}

.layout .flex-full {
  height: 100%;
  width: 100%;
  margin: 0;
}

.layout .user-selection-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.layout .user-selection-container .nav-tabs {
  border-bottom: none;
}

.layout .user-selection-container .tab-content,
.layout .user-selection-container .tab-content .tab-pane.active,
.layout .user-selection-container .input-group {
  height: 100%;
}

/* .layout .user-selection-container .input-group{
    background: #f2f2f8;
} */

.layout .user-selection-box {
  overflow: auto;
  position: absolute;
  width: calc(100% - 30px);
  height: calc(100% - 55px);
  top: 50px;
  left: 15px;
  padding: 0 0 10px 0;
}

.layout .user-selection-box .user-info {
  display: flex;
  padding: 0;
  margin: 10px 10px 0;
  border: none;
  border-radius: 0;
  font-size: 0.875rem;
  background: inherit;
  color: #062b60;
  white-space: nowrap;
  min-width: 300px;
}

.layout .user-selection-box .user-info > .user-info-detail {
  text-overflow: ellipsis;
  overflow: hidden;
}

.layout .user-selection-box .user-info .top-info {
  font-weight: 500;
}

.layout .user-selection-box .user-info .bottom-info {
  font-size: 0.81rem;
  color: #535963;
}

.layout .user-selection-box .user-info ul li {
  margin: 7px 0 0;
}

.layout .user-selection-box .collapse {
  padding-right: 2px;
}

.layout .user-selection-box .user-info .action {
  width: 34px;
  height: 46px;
}

.layout .user-selection-box .user-info .action button {
  padding: 0;
  height: 100%;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1069d5;
  background-color: #d4d4d4;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  /* box-shadow: 1px 1px 2px #888; */
}

.layout .user-selection-box .user-info .action button:hover {
  background-color: #b7b7b7;
}

.layout .user-selection-box .user-info .info {
  width: 100%;
  overflow: hidden;
}

.layout .user-selection-box .user-info .info .info-container {
  display: flex;
  height: 46px;
  background-color: #f5f5f5;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  /* box-shadow: 1px 1px 2px #888; */
  /* padding-left: 4px; */
  margin-right: 2px;
}

.layout .user-selection-box .user-info .info .info-container .display-avatar {
  height: 38px;
  width: 38px;
  min-width: 38px;
  min-height: 38px;
  background-color: #b6d3f2;
  border-radius: 0.25rem;
  margin: 4px;
  padding: 4px;
  overflow: hidden;
  color: #666;
  /* box-shadow: -1px -1px 2px #888; */
}

.layout .user-selection-box .user-info .info .info-container .display-avatar * {
  height: 100%;
  width: 100%;
}

.layout .user-selection-box .user-info .info .info-container.group {
  cursor: pointer;
  /* margin-bottom: 4px; */
}

.layout .user-selection-box .user-info:hover > .info > .info-container,
.layout .user-selection-box .user-info .info:hover .info-container.group,
.layout .user-selection-box .user-info .info .info-container:hover {
  background-color: #e2e2e8;
}

.layout .user-selection-box .user-info .info .user-info-detail {
  width: 100%;
  padding: 0 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.layout .user-selection-box .user-info .info .user-info-detail .top-info,
.layout .user-selection-box .user-info .info .user-info-detail .bottom-info {
  height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.layout .btn-add-user {
  border: 0;
}

.layout .btn-add-user .material-icons {
  width: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout .doc-info .input-group {
  margin-top: 10px;
  min-width: 640px;
  height: 46px;
}

.layout .doc-info .document-info-key {
  min-width: 240px;
  font-size: 0.81rem;
  color: #535963;
}

@media only screen and (max-width: 767px) {
  .layout .signer-list-wrapper .viewer-info {
    float: none;
  }

  .layout .doc-info .document-info-key {
    font-size: 10px;
    min-height: 28px;
  }

  .layout .doc-info .signer-list-wrapper .document-info-key {
    height: 14px;
  }

  .responsiveCSS .form-row {
    margin: 0;
  }
}

.layout .doc-info .document-info-value {
  height: 100%;
  font-size: 0.85rem;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  color: #062b60;
  /* overflow: hidden;
    white-space: nowrap; */
}

@media only screen and (max-width: 767px) {
  .layout .doc-info .document-info-value {
    font-size: 0.65rem;
    min-height: 28px;
  }
}

.layout .filebox-shortcut-path {
  background-color: #fff;
}

.layout .document-row-list.has-linkage {
  background-color: #dff2fc;
}

.layout .document-row-list.selected {
  background-color: #e8e8ec;
}

.layout .document-row-list.selected:hover {
  background-color: #f2f2f8;
}

.layout .document-row-list:hover {
  background-color: #f2f2f8;
}

.layout .document-row-list td {
  vertical-align: middle;
  padding: 0;
}
.layout .document-row-list td.selection-box svg {
  font-size: 1.2rem;
  color: rgb(83, 89, 99);
}

.layout .document-row-list .record-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  font-size: 18px;
}

.table {
  color: inherit;
  table-layout: fixed;
}

.resize-handle {
  cursor: col-resize;
  position: absolute;
  top: 0;
  right: 0px;
  /* bottom: 0; */
  z-index: 10;
  width: 5px;
}

.resize-handle:hover {
  border-right: 1px solid #dee2e6;
}

.th-selection-box {
  width: 30px;
}

.th-stamp-box,
.th-stamp-delete {
  width: 40px;
  max-width: 40px;
}

.th-creator,
.th-sign-level,
.th-doctype,
.th-document-created-date {
  /* max-width: 90px; */
  position: relative;
  /* min-width: 50px; */
  width: 7%;
}

.th-function-type {
  width: 7.04%;
  position: relative;
  /* max-width: 90px; */
}

.th-preservation-require,
.th-amount-info {
  width: 8.21%;
  position: relative;
  /* max-width: 105px; */
}

.th-action {
  width: 130px;
  position: relative;
}

.th-counter-party {
  width: 15%;
  position: relative;
  /* max-width: 200px; */
}

.th-imprints {
  position: relative;
  /* max-width: 200px; */
}

.layout .document-row-list .counter-party-info span {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.layout .document-row-list .title-info .document-files {
  display: flex;
  width: 100%;
}

.layout .document-row-list .title-info .document-files .file-name-label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.layout .document-row-list .title-info .more-files {
  position: relative;
  width: 100%;
}

.layout .document-row-list .title-info .more-files .more-files-link {
  margin: 0 8px;
  cursor: default;
}

.layout .document-row-list .title-info .more-files .more-files-block {
  display: none;
}

.layout
  .document-row-list
  .title-info
  .more-files
  .more-files-block
  .file-label:hover {
  background-color: #e2e2e8;
}

.layout .document-row-list .title-info .more-files:hover .more-files-block {
  display: block;
  position: absolute;
  top: 0;
  left: 100%;
  width: auto;
  min-width: 200px;
  /* max-width: 400px; */
  z-index: 10;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.layout
  .document-row-list
  .title-info
  .more-files
  .more-files-block
  .file-label {
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 15px;
}

.layout .document-row-list .amount-info .inner-container span {
  text-align: right;
  padding-right: 1em;
}

.layout .document-row-list .doc-row-action,
.layout .document-row-list .sign-status {
  vertical-align: middle;
  text-align: center;
  color: #9495a7;
}

.layout .document-row-list .document-download {
  font-size: 24px;
}

.layout .document-row-list .document-downloading {
  font-size: 24px;
  cursor: default;
}

.layout .document-row-list .document-delete {
  font-size: 24px;
  color: #ec163f;
}

.layout .document-row-list .action-icon {
  display: inline-block;
  padding: 0 4px;
  font-size: 24px;
  margin-left: auto;
  margin-right: auto;
}

.layout .document-row-list .action-button {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .layout .document-detail .signer-list-wrapper .user-info {
    height: 36px;
  }

  .layout .document-detail .signer-list-wrapper .user-info .display-avatar {
    min-width: 28px;
    min-height: 28px;
    width: 28px;
    height: 28px;
  }

  .layout .document-detail .signer-list-wrapper .user-info .top-info {
    font-size: 12px;
  }

  .layout .document-detail .signer-list-wrapper .user-info .bottom-info {
    font-size: 11px;
  }
}

.layout .document-detail .signer-list-wrapper .sign-status.signed,
.layout
  .document-detail
  .signer-list-wrapper
  .user-info
  .sign-status.signed
  .icon-container {
  padding: 0;
  text-align: left;
}

.layout .document-detail .signer-list-wrapper .user-info .icon-container {
  width: 24px;
  margin: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(96, 95, 110);
}

.layout .document-detail .signer-list-wrapper .user-info .icon-container > svg {
  font-size: 23px;
}

@media only screen and (max-width: 767px) {
  .layout
    .document-detail
    .signer-list-wrapper
    .user-info
    .icon-container
    > svg {
    font-size: 21px;
  }
}

.layout
  .document-detail
  .signer-list-wrapper
  .user-info
  .icon-container
  span.action-resend-email {
  width: 22px !important;
  height: 22px !important;
  cursor: pointer;
}

.layout .document-detail .signer-list-wrapper .sign-status.token-expired,
.layout
  .document-detail
  .signer-list-wrapper
  .user-info
  .sign-status.token-expired
  .icon-container,
.layout .document-row-list .sign-status.wait-sign {
  color: #ec163f;
}

.layout td > div,
.layout th > div {
  padding: 5px 5px;
  /* min-height: 50px; */
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.layout td:first-child > div,
.layout th:first-child > div {
  margin-left: 10px;
}

.layout td:last-child > div,
.layout th:last-child > div {
  margin-right: 10px;
}
.layout tbody tr {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #e2e2e8;
  padding: 0;
  min-height: 50px;
  font-size: 0.81rem;
}

.layout .document-row-list td .inner-container span {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.layout .document-row-list .sign-status .inner-container {
  align-items: flex-start;
  justify-content: center;
}

.layout .document-row-list .evaluation-summary-info {
  font-size: 14px;
  line-height: 18px;
  padding: 0 8px;
}

/* .layout .document-row-list .document-evaluated-status { */

/* display: flex;
    align-items: flex-end; */

/* height: 27px; */

/* } */

.layout .document-row-list .evaluated-status .status-label {
  border: 2px solid #067bfc;
  color: #067bfc;
  border-radius: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px 10px;
  font-size: 12px;
  font-weight: bold;
}

.layout .evaluate-option {
  margin-left: 10px;
}

.layout .evaluate-option label {
  margin-left: 0;
  margin-bottom: 0;
}

.layout .date-noti label {
  font-size: 14px;
  color: #535963;
}

.css-6rspzu-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 0.85rem;
}

@media only screen and (max-width: 767px) {
  .css-qfz70r-MuiFormGroup-root {
    margin-left: 15px;
  }

  .css-6rspzu-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 12px;
  }

  .css-7njd72-MuiButtonBase-root-MuiRadio-root {
    padding: 3px 9px;
  }
}
.layout .evaluation-wrapper .body-wrapper {
  padding-bottom: 50px;
}
.layout .evaluate-header {
  max-width: 13em;
}
.layout .evaluate-header.bg-lightblue,
.layout .evaluate-info.bg-lightblue {
  background-color: #dff2fc;
}

.layout .document-row-list .evaluated-status .icon {
  /* margin-right: 4px; */
  height: 24px;
  font-size: 24px;
  color: #1976d2;
}

.layout .document-row-list .evaluated-status-icon-medium {
  height: 16px;
  width: 16px;
  color: #8d8d8d;
}

.layout .document-row-list .evaluated-status-icon-medium.ok,
.layout .document-row-list .evaluated-status .icon.ok {
  color: rgb(23, 168, 69);
}

.layout .document-row-list .evaluated-status-icon-medium.ng,
.layout .document-row-list .evaluated-status .icon.ng {
  color: #e9ae0a;
}

.layout .document-row-list .evaluated-status-mark-medium {
  height: 16px;
  width: 16px;
}

.layout .document-row-list .evaluated-status-mark-mini {
  height: 10px;
  width: 10px;
}

.layout .document-row-list .evaluated-status-mark-medium,
.layout .document-row-list .evaluated-status-mark-mini {
  border-radius: 50%;
  background-color: #8d8d8d;
}

.layout .document-row-list .evaluated-status-mark-medium.ok,
.layout .document-row-list .evaluated-status-mark-mini.ok {
  background-color: #078c07;
}

.layout .document-row-list .evaluated-status-mark-medium.ng,
.layout .document-row-list .evaluated-status-mark-mini.ng {
  background-color: #e9ae0a;
}

.layout .table thead {
  position: relative;
  z-index: 1;
}

.layout .audit-log-container .header-wrapper,
.layout .evaluation-container .header-wrapper,
.layout .circle-container .header-wrapper,
.layout .verification-container .header-wrapper,
.layout .sender-container .header-wrapper,
.layout .signer-container .header-wrapper,
.layout .table thead tr,
.layout #datalist thead tr,
.stickyHeader,
.search-container .header-wrapper {
  position: sticky;
  z-index: 2;
  border-bottom: 1px solid #e2e2e8;
}

.layout .audit-log-container .header-wrapper.above,
.layout .evaluation-container .header-wrapper.above,
.layout .circle-container .header-wrapper.above,
.layout .verification-container .header-wrapper.above,
.layout .sender-container .header-wrapper.above,
.layout .signer-container .header-wrapper.above {
  border-bottom: none;
  z-index: 3;
}
.layout .verification-container .btn-custom svg,
.layout .sender-container .btn-custom svg,
.layout .signer-container .btn-custom svg {
  color: rgb(83, 89, 99);
  font-size: 1.2rem;
}

.layout .verification-container .btn-custom.btn-primary svg,
.layout .sender-container .btn-custom.btn-primary svg,
.layout .signer-container .btn-custom.btn-primary svg {
  color: #fff;
}
.layout .verification-container .btn-custom-group {
  /* box-shadow: none; */
}
.layout th > div {
  min-height: 30px;
}

.stickyHeader {
  background-color: #fff;
  top: 0;
  height: 60px;
}

.layout .table thead tr,
.layout #datalist thead tr {
  position: sticky;
  background: #fff;
  border: none;
  border-radius: 0;
  padding: 0;
  top: 60px;
}

.layout .page-settings .table thead tr,
.layout .page-settings #datalist thead tr {
  top: 0;
}

.layout #datalist.table {
  overflow: clip !important;
}

#page-settings-batch-download .batch-download-list {
  width: 100%;
}

.layout .table thead tr.shed,
.layout #datalist thead tr.shed {
  top: 60px;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
}

/* .layout thead tr:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    border-bottom: 1px solid #e2e2e8;
} */

.layout .header-wrapper.above {
  padding: 0;
  height: auto;
}
.layout .evaluation-container .header-wrapper.above {
  height: 0;
}
.layout .header-wrapper.above .header-left .as-link {
  /* width: 130px;
  letter-spacing: 0;
  padding: 0 16px 0 10px; */
  border-radius: 4px;
  height: 36px;
  border-color: #007bff;
  padding-left: 17px;
  letter-spacing: 0.00938em;
}
.layout .header-wrapper.above .header-left .as-link > span {
  margin: 0;
}
.layout .header-wrapper.above .header-left .as-link > span > svg {
  font-size: 1.2rem;
}

.layout .header-wrapper.bottom {
  padding: 6px 15px 6px 0px;
}
.layout .header-wrapper.bottom .header-left button:first-child svg {
  font-size: 1.2rem;
  color: rgb(83, 89, 99);
}
.layout
  .evaluation-container
  .header-wrapper.bottom
  .header-left
  button:first-child
  svg {
  color: #fff;
}

.layout .document-detail {
  display: none;
  position: absolute;
  overflow-x: hidden;
  /* width: 100%; */
  height: 100%;
  background-color: #fff;
  z-index: 4;
  transition-duration: 0.4s;
  transition-timing-function: ease;
}

.layout .document-detail:not(.active) {
  margin-left: 100%;
  transform: rotateY(90deg);
  transform-style: preserve-3d;
}

.layout div.document-detail.active + div {
  overflow: hidden;
  height: 100%;
}

.layout .document-detail.static {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: 0;
  background-color: #fff;
  transform: none;
  transform-style: inherit;
}
.layout .document-detail.active {
  display: block;
}

.layout .document-detail.error-layout {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: 0;
  background-color: #fff;
  transform: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.layout .document-detail .access-deny-title {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 52px;
}

.layout .document-detail .document-detail-wrapper {
  display: flex;
  flex-direction: column;
  /* min-height: 100%; */
  /* height: calc(100% - 60px); */
  min-height: 100%;
}
.layout .document-detail .document-detail-wrapper.evaluation-wrapper {
  overflow: auto;
}
.layout
  .document-detail
  .document-detail-wrapper.evaluation-wrapper
  .header-wrapper
  .document-title {
  width: 0 !important;
}
.layout .evaluation-container #tableId {
  min-width: inherit !important;
  width: calc(100vw - 256px) !important;
}
.layout .document-detail .document-detail-wrapper.evaluation-wrapper #tableId {
  min-width: inherit !important;
  width: inherit !important;
}

.layout .document-detail .document-detail-wrapper .document-detail-form {
  overflow: hidden;
  min-height: calc(100vh - 120px);
}

.layout .search-container {
  height: calc(100vh - 60px);
  overflow-x: auto;
}
.layout .template-new .btn-action,
.layout .template-new .btn-return,
.layout .search-container .btn-action,
.layout .search-container .btn-return {
  color: #626669;
  border: none;
  background-color: transparent;
  padding: 0 8px;
  font-size: 30px;
  width: 46px;
}

.layout .document-detail-container .form-group {
  min-height: 40px;
  margin-bottom: 7px;
}
@media only screen and (max-width: 767px) {
  .layout .document-detail-container .form-group {
    min-height: inherit;
  }
}
.layout .form-control {
  border-color: #e2e2e8;
}

@media only screen and (max-width: 767px) {
  .layout .document-detail-container .form-group {
    height: inherit;
  }
  .layout .document-detail-container .form-group .btn-select-action {
    border: 1px solid rgba(25, 118, 210, 1);
    padding: 7px 21px;
    border-radius: 4px;
    color: #1976d2;
    font-weight: 500;
    font-size: 0.9375rem;
  }
  .layout .document-detail-container .form-group .btn-select-action::before {
    display: none;
  }
  .layout .document-detail-container .form-group .btn-select-action > svg {
    fill: rgba(25, 118, 210, 1);
  }

  .layout .document-detail-container .form-group .btn-select-action > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 0;
  }

  .layout
    .document-detail-container
    .form-group
    .btn-select-action
    .btn-select-icon {
    margin-right: 8px;
    margin-left: -4px;
  }
}

.layout .document-detail-container .form-group .document-info-value {
  /* border:none; */
  color: #062b60;
}

.layout .document-detail-container .form-group .form-control {
  font-size: 0.85rem;
}
.layout .document-detail-container .form-group input.form-control,
.layout .document-detail-container .is-edit .form-group select.form-control {
  background-color: #f8f8ff;
}

@media only screen and (max-width: 767px) {
  .layout .document-detail-container .form-group .form-control {
    padding: 0.175rem 0.55rem;
  }
}

.layout .document-detail-container .info-label.form-group {
  max-height: 40px;
  padding: 10px 0;
  margin: 0;
  height: unset;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.layout .document-detail-container .document-status-label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout .document-detail-container .document-status-label .document-info-value {
  border: 3px solid #3b74d7;
  color: #3b74d7;
  border-radius: 18px;
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .layout
    .document-detail-container
    .document-status-label
    .document-info-value {
    height: 30px;
  }
}

.layout .status-container {
  display: flex;
  align-items: flex-end;
  height: 27px;
}

.layout .document-progress {
  display: flex;
  align-items: flex-end;
  height: 27px;
  margin-right: 4px;
}

.layout .evaluate-detail {
  display: flex;
  align-items: center;
  height: 27px;
}

.layout .evaluate-detail.evaluated-status .icon {
  font-size: 16px;
}

.layout .document-progress .status-label {
  border: 2px solid #1976d2;
  color: #1976d2;
  border-radius: 11px;
  height: 22px;
  line-height: 22px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
  font-size: 0.7rem;
  font-weight: 500;
}

.layout .unsigned-container {
  display: flex;
  align-items: flex-end;
  height: 27px;
}

.layout .document-progress.draft .status-label,
.layout
  .document-detail-container
  .document-status-label
  .document-info-value.draft {
  border: 2px solid #a8aabf;
  color: #a8aabf;
}

.layout .document-progress.expired .status-label,
.layout
  .document-detail-container
  .document-status-label
  .document-info-value.expired {
  color: #6f718c;
  border-color: #6f718c;
}

.layout .document-progress.file-error .status-label,
.layout .document-progress.error .status-label,
.layout
  .document-detail-container
  .document-status-label
  .document-info-value.file-error,
.layout
  .document-detail-container
  .document-status-label
  .document-info-value.error {
  color: #e0463b;
  border-color: #e0463b;
}

.layout .document-progress.returned-cancel .status-label,
.layout .document-progress.returned .status-label,
.layout
  .document-detail-container
  .document-status-label
  .document-info-value.returned-cancel,
.layout
  .document-detail-container
  .document-status-label
  .document-info-value.returned {
  color: #e0463b;
  border-color: #e0463b;
}

.layout
  .document-detail-container
  .document-status-label
  .document-info-value.returned-cancel {
  color: #e0463b;
  border-color: #e0463b;
}

.label-error {
  color: #e0463b;
  border-color: #e0463b;
}

.layout .document-progress.guest-expired .status-label,
.layout
  .document-detail-container
  .document-status-label
  .document-info-value.guest-expired {
  color: #c4830c;
  border-color: #c4830c;
}

.layout .document-progress.signed .status-label,
.layout .document-progress.send-sender .status-label,
.layout .document-progress.confirmed .status-label,
.layout
  .document-detail-container
  .document-status-label
  .document-info-value.signed,
.layout
  .document-detail-container
  .document-status-label
  .document-info-value.send-sender,
.layout
  .document-detail-container
  .document-status-label
  .document-info-value.confirmed {
  color: #078c07;
  border-color: #078c07;
}

.layout
  .document-detail-container
  .document-status-label
  .document-info-value
  .icon {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  .layout
    .document-detail-container
    .document-status-label
    .document-info-value
    .icon {
    font-size: 18px;
  }
}

.layout
  .document-detail-container
  .document-status-label
  .document-info-value
  .label {
  width: 100%;
  text-align: center;
}

.layout .document-detail .signer-list-wrapper {
  display: block;
}

/* .layout .btn.new-document {
    margin: 0;
} */

.layout .warning {
  color: #e9ae0a;
}

.layout .no-certificate-warning {
  font-size: 24px;
  color: #e9ae0a;
}

.layout .guest-no-certificate-warning {
  font-size: 20px;
  color: #e9ae0a;
  position: absolute;
  left: 8px;
}

.layout .guest-no-certificate-warning-title {
  font-size: 18px;
  color: #e9ae0a;
  margin-right: 8px;
}

.layout .guest-certificate-selection {
  width: 100%;
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
}

.layout .guest-certificate-selection .dropdown-toggle.nav-link {
  padding: 10px 0 10px 10px;
  /* background-color: #f2f2f8; */
}

.layout .guest-certificate-selection .dropdown-menu .dropdown-item {
  padding-left: 40px;
  position: relative;
}

.layout .document-selected-list {
  margin: 0;
}

.layout .document-selected-list .document-selected-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.layout .document-selected-header {
  font-weight: 500;
  padding: 15px 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menu-hidden.active .document-selected-list {
  padding: 15px 8px 15px 15px;
  overflow: auto;
  height: calc(100% - 54px);
}

.layout .document-selected-list .document-info {
  font-size: 0.81rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 4px 10px 4px 0;
}

.layout .document-selected-list .document-action {
  color: #535963;
  padding: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout .document-selected-list .document-action .btn-remove {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  padding: 8px;
}

.layout .document-selected-list .document-action:hover .btn-remove {
  background-color: #f2f2f8;
}

.layout .page-error {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout .template-preview,
.layout .template-new {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  transition-duration: 0.4s;
  transition-timing-function: ease;
}

.layout .template-row-contrainer {
  position: relative;
  height: 100%;
}

.layout .template-preview.active {
  left: 0;
}

.layout .template-new.active {
  left: 0;
}

.access-log-head {
  margin: 4px 0;
  padding: 1px;
  line-height: 16px;
  display: flex;
}

.access-log-body {
  font-size: 0.75rem;
  overflow: auto;
  padding-left: 10px;
}

.access-log-collapse {
  height: 0px;
}

.access-log-selector-label {
  margin-bottom: 4px;
}

.access-log-selector-itemlist {
  padding: 20px;
  height: 400px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  overflow-y: scroll;
}

.access-log-selector-item label {
  display: block;
  cursor: pointer;
  /* box-shadow: 0 0 6px 2px #eee; */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.access-log-selector-item div {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.layout .accesslog {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f2f2f8;
  border-left: 1px solid #e2e2e8;
  padding: 15px 15px 15px 15px;
  left: 100%;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  z-index: 10;
  font-size: 0.7rem;
  word-wrap: break-word;
}

.layout .accesslog.active {
  left: 0%;
}

.operation-log-top {
  border-bottom: 1px solid #dadae4;
}

.operation-log-top .lz-flex-1 {
  padding: 10px 0;
}

.operation-log-top .operation-log-update-date,
.operation-log-top .operation-log-update-user {
  font-size: 0.7rem;
  color: #545d75;
}

.operation-log-top .operation-log-content {
  margin-top: 5px;
  font-size: 0.7rem;
  font-weight: 500;
}

.pg-viewer-wrapper #docx {
  background-color: #ddd;
  height: auto !important;
  padding: 20px 0;
}

.layout .doc-key-container {
  list-style: none;
  padding: 0;
  margin: 0;
}

.layout .doc-key-item {
  padding: 4px 8px;
  margin: 8px 4px;
  background-color: #f5f5f5;
  border-left: 10px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.layout .form-group.no-margin {
  margin: 0;
}

.layout .form-group.no-margin.doc-key-item-list {
  height: 100%;
  overflow-y: auto;
}

.layout .ReactTable {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  top: 0;
  left: 0;
  border: none;
}
.layout .table td,
.table thead th {
  border-top: solid 0px !important;
  border-bottom: solid 0px !important;
}

.layout .table td {
  overflow: hidden;
}
.table thead th {
  font-size: 0.75rem;
  font-size: clamp(0.65rem, 1.03vw, 0.75rem);
  color: #828688;
  font-weight: 500;
  padding: 0;
  white-space: nowrap;
}
.table thead th span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.layout .table-bordered thead th {
  border-top: solid 0px !important;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
}

.layout .table-bordered tbody td {
  vertical-align: middle;
  white-space: nowrap;
}

.layout .record-form-detail {
  position: fixed;
  height: calc(100% - 60px);
  top: 60px;
  left: 110%;
  background-color: #fff;
  height: calc(100% - 60px);
  width: 40%;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  overflow: auto;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  padding-top: 20px;
  margin-top: -1px;
}

.layout .record-form-detail.active {
  left: 60%;
}

.layout .record-form-detail-head,
.layout .hader-form-action {
  display: flex;
}

.layout .record-form-detail-head .btn.btn-primary,
.layout .record-form-detail-head .btn.btn-secondary {
  margin: 0 7px;
}
.layout .record-form-detail-body {
  overflow: auto;
}

.layout .record-form-detail-body-container {
  display: flex;
  flex-direction: column;
}

.layout .record-form-detail-body .form-group {
  margin-bottom: 0px;
}

.layout .record-form-detail-body .form-group .input-group-prepend .btn {
  height: unset;
}

.layout .btn-attribute {
  position: absolute;
  z-index: 2;
  top: 0;
  background-color: transparent;
  border: none;
  padding: 0;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.layout .add-attribute {
  left: -56px;
}

.layout .remove-attribute {
  left: -30px;
}

.layout .forward-user-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.layout .forward-user-list .user-info {
  background-color: #f5f5f5;
  border-radius: 50px 8px 8px 50px;
  cursor: pointer;
}

.layout .forward-user-list .user-info:hover,
.layout .forward-user-list .user-info.selected {
  background-color: #cccccc;
}

.layout .forward-user-list .user-avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 40px;
  display: block;
  padding: 4px;
}

.layout .forward-user-wrapper .forward-user-info {
  height: 100%;
  width: 100%;
  padding: 0 15px;
}

.layout .forward-user-wrapper .forward-user-card {
  height: unset;
  /* padding: 15px; */
}

.layout .forward-user-wrapper .forwardUser-profile {
  display: flex;
  align-items: center;
  background-color: #f2f2f8;
  word-break: break-all;
  border-radius: 999px;
}

.layout .forward-user-wrapper .forward-user-card .user-avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 40px;
  display: block;
  padding: 4px;
}

.layout .viewer-avatar {
  border-radius: 50px;
  overflow: hidden;
  height: 20px;
  width: 20px;
  margin-left: -7px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout .forward-user-wrapper .forward-user-card .user-profile {
  width: calc(100% - 48px);
  padding: 0 4px;
}

.layout .forward-user-wrapper .forward-user-card .top-info {
  line-height: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.layout .forward-user-wrapper .forward-user-card .top-info span {
  font-size: 0.83rem;
  font-weight: 500;
}

.layout .forward-user-wrapper .forward-user-card .bottom-info {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.layout .forward-user-wrapper .forward-user-card .bottom-info span {
  font-size: 0.81rem;
}

.layout .display-avatar div:not([avatar]),
.layout .display-avatar svg,
.layout .viewer-avatar div:not([avatar]),
.layout .viewer-avatar svg,
.layout .user-avatar div,
.layout .user-avatar svg {
  display: block;
  width: 100% !important;
  height: 100% !important;
  padding: 1px;
}

.icon.tenant-switch .MuiBox-root .MuiInput-root::before,
.header-right .MuiBox-root .MuiInput-root::before {
  display: none;
}

.icon.tenant-switch .MuiBox-root .MuiInput-root > div:focus,
.header-right .MuiBox-root .MuiInput-root > div:focus {
  background: none;
}

.icon.tenant-switch .MuiBox-root .MuiInput-root > div,
.header-right .MuiBox-root .MuiInput-root > div,
ul.MuiList-root li {
  font-size: 0.81rem;
}

.layout.modal .modal-dialog .full-height-modal-body {
  height: calc(100vh - 15rem);
}

.layout .flex-center {
  align-items: center;
}

.layout .user-linkage-icon {
  width: 40px;
  height: 40px;
}

@media (min-width: 767px) {
  .layout.modal.modal-responsive .modal-dialog.modal-xl,
  .layout.modal.modal-responsive .modal-dialog.modal-lg {
    max-width: 50%;
    margin: 1.75rem auto;
  }

  .layout.modal.modal-responsive .modal-dialog.modal-sm {
    max-width: 30%;
    margin: 1.75rem auto;
  }
}

@media (max-width: 991px) {
  .layout.modal.modal-responsive .modal-dialog.modal-xl,
  .layout.modal.modal-responsive .modal-dialog.modal-lg {
    margin: auto;
    max-width: 100%;
  }
  .modal-dialog {
    max-width: 980px;
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .layout.modal.modal-responsive .modal-dialog.modal-sm {
    max-width: 100%;
    margin: auto 1.75rem;
  }
  .layout.modal .modal-dialog .full-height-modal-body {
    height: calc(100vh - 133px - 9rem);
  }
}

#page-settings-guest .document-row-list td {
  /* max-width: 0; */
  font-size: clamp(10px, 1vw, 13px);
}

#page-settings-guest .document-row-list td.no-overflow {
  max-width: 1px;
  overflow: visible;
  min-width: 16em;
}

#page-settings-guest .document-row-list td > div {
  width: 100%;
  font-size: clamp(10px, 1vw, 13px);
}

#page-settings-guest .document-row-list td.no-overflow > div {
  align-items: center;
}
#page-settings-guest .document-row-list td.admin-role > div {
  align-items: center;
}

table.user-settings-list,
table.guest-settings-list {
  table-layout: fixed;
}
.user-settings-list td > div,
.guest-settings-list td > div,
.tenant-setting-list td > div,
.appcert-setting-list td > div,
.stamp-setting-list td > div,
.counter-party-setting-list td > div {
  min-height: 60px;
}

.tenant-setting-list .tenant-id {
  width: 14%;
}

.tenant-setting-list .tenant-name {
  width: 40%;
}

.tenant-setting-list .contract-start-date {
  width: 23%;
}

.tenant-setting-list .limit-contract-person {
  width: 22%;
}

.tenant-setting-list .tenant-company-id {
  width: 0;
}

.user-settings-list th {
  white-space: nowrap;
}

/* .user-settings-list .user-user-id {
  width: 6.9%;
}

.user-settings-list .user-user-name {
  width: 8.3%;
}

.user-settings-list .user-user-email {
  width: 20%;
}

.user-settings-list .user-personal-jcan {
  width: 20%;
}

.user-settings-list .user-custom-certificate {
  width: 20%;
} */

.user-settings-list .user-administrator-role > div {
  align-items: center;
}

.user-settings-list .user-user-status {
  width: 150px !important;
}

.user-settings-list .user-user-status div {
  align-items: center;
}

.user-settings-list .user-user-trash {
  width: 60px !important;
  text-align: center;
}

.user-settings-list .css-dkl93a-MuiChip-root {
  font-size: clamp(10px, 1vw, 13px);
}

.guest-settings-list .guest-user-id {
  width: 80px;
}

.guest-settings-list .guest-linkage {
}
.guest-settings-list .guest-linkage > div {
  padding-left: 0;
  padding-right: 0;
}
.guest-settings-list .guest-user-name {
  width: 10%;
}
.guest-settings-list .guest-user-email {
  width: 15%;
}

.guest-settings-list .guest-personal-jcan {
  width: 15%;
}

.guest-settings-list .guest-custom-certificate {
  width: 15%;
}

.guest-settings-list .guest-registration-status-action {
  width: 15%;
  min-width: 158px;
  max-width: 178px;
}

.guest-settings-list .guest-registration-status {
  width: 10%;
}

/* verification-stamp */
.verification-stamp-detail {
  width: 60px;
}

.verification-stamp-creator {
  width: 8em;
}

.verification-stamp-function {
  width: 6em;
}

.verification-stamp-imprints {
  width: 11em;
}

.verification-stamp-doctitle {
  min-width: 150px;
}

.verification-stamp-amount {
  width: 10%;
}

.verification-stamp-date {
  width: 8em;
}

/* verification-form */
.verification-form {
  position: relative;
  display: flex;
  height: 100%;
}
.verification-form-col-child-wrap {
  padding: 0 30px;
  overflow-y: auto;
  height: calc(100vh - 60px);
}
.verification-form-col {
  /* flex: 1; */
  position: fixed;
  width: calc((100vw - 256px) / 2);
}
.verification-form-col:first-child {
  padding: 0 30px;
  overflow-y: auto;
  height: calc(100vh - 60px);
}
.verification-form-col:last-child {
  top: 60px;
  right: 0;
  height: 100%;
}
.verification-form-col .full-form {
  justify-content: flex-start;
}
.verification-form .input-group-prepend button {
  height: calc(1.5em + 0.75rem + 1px);
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: #1976d2;
}
.verification-form .input-group-prepend button span {
  font-size: 0.875rem;
}

.evaluated-date {
  width: 13.4em;
}
.evaluated-verifier {
  width: 12.5em;
}
.evaluated-results {
  width: 10%;
  text-align: center;
}
.layout th.evaluated-results > div {
  text-align: center;
  align-items: center;
}
.layout th.evaluated-results > div > span {
  display: block;
  text-align: center;
}
.layout td:last-child.stamp-box > div,
.layout th:last-child.stamp-box > div {
  align-items: center;
  text-align: center;
}
.evaluated-creatior {
  width: 10%;
}

.evaluated-status {
  text-align: center;
}

.evaluation-icon {
  width: 55px;
}
.evaluation-creator {
  width: 10em;
}
.evaluation-function {
  width: 6em;
}
.evaluation-document {
  width: 11.8em;
}
.evaluation #doc-title {
  max-width: 320px;
}
.evaluation-amount {
  width: 10%;
}
.evaluation-date {
  width: 8em;
}
.evaluation-require {
  width: 6em;
}
.evaluation-document-title {
  min-width: 9em;
}
.evaluation-data {
  width: 9em;
}

.search-creator {
  width: 12em;
}

.search-function {
  width: 8em;
}
.search-data {
  width: 9em;
}
.search-document {
}
.search-counter-party {
  width: 16em;
}
.search-amount {
  width: 12em;
}
.search-date {
  width: 9em;
}
.search-dialog {
  background-color: rgba(242, 242, 247, 255);
  outline: none;
}
.clear-all-filter {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}
.right_body_drive .MuiTableBody-root td:nth-child(2) {
  font-weight: 500;
}
.right_body_drive .MuiTableBody-root td:nth-child(3),
.right_body_drive .MuiTableBody-root td:nth-child(4),
.right_body_drive .MuiTableBody-root td:nth-child(5),
.right_body_drive .MuiTableBody-root td:nth-child(6) {
  padding: 0 8px 0 0;
  color: #535963 !important;
}

.full-right-body-with-file-list .container,
.full-right-body-with-file-list .container-lg,
.full-right-body-with-file-list .container-md,
.full-right-body-with-file-list .container-sm,
.full-right-body-with-file-list .container-xl {
  max-width: 100%;
}

.batch-download-list .batch-download-date {
  width: 20%;
}

.batch-download-list .batch-download-user-name {
  width: 20%;
}

.batch-download-list .batch-download-range {
  width: 40%;
}

.batch-download-list .batch-download-status {
  flex: 1;
}

.batch-download-status-icon > div {
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  margin: 0;
  align-items: center;
  border-radius: 11px;
  display: inline-flex;
  flex-direction: row;
  font-size: 0.7rem;
  font-weight: 500;
  height: 22px;
  justify-content: space-between;
  line-height: 22px;
  padding: 0 10px;
}

.status-ready-label {
  border-color: #078c07;
  color: #078c07;
}

.status-processing-label {
  border-color: #c4830c;
  color: #c4830c;
}

.status-uploading-label {
  border-color: #007bff;
  color: #007bff;
}

.status-error-label {
  border-color: #e0463b;
  color: #e0463b;
}

.status-downloaded-label {
  border-color: #a8aabf;
  color: #a8aabf;
}

.business_number_tooltip_helper {
  white-space: pre-line;
}
/* background-color: white; */
.ocr-block {
  z-index: 2;
  cursor: pointer;
  font-size: 11px;
  position: absolute;
  overflow: hidden;
}

.ocr_block_received_date {
  background: rgba(255, 72, 72, 0.4);
}
.ocr_block_received_date:hover {
  transition: ease-in-out 0.1s;
  border-bottom: 2px solid #ff4848;
}

.ocr_block_amount {
  background: rgba(255, 184, 34, 0.3);
}
.ocr_block_amount:hover {
  transition: ease-in-out 0.1s;
  border-bottom: 2px solid #ffb822;
}

.startdate,
.enddate,
.search-btn,
.tilde {
  padding-left: 10px;
}
.tilde {
  font-size: 25px;
}
.th-audit-log-create_datetime,
.th-audit-log-create_user {
  /* max-width: 90px;
  min-width: 8em; */
  width: 15%;
}
.th-audit-log-ip_address {
  /* max-width: 90px;
  min-width: 8em; */
  width: 20%;
}

.th-audit-log-url {
  width: 30%;
  /* max-width: 90px; */
}

.th-audit-log-document_id,
.th-audit-log-operation_details {
  width: 10%;
}
.url-nomarl {
  color: #062b60;
}

.verification-data-container {
  display: flex;
  flex-direction: row;
  /*justify-content: space-between;*/
  width: 100%;
  flex: 1;
}

.verification-data-table {
  display: flex;
}
.verification-data-container .verification-table .preservation_require_text,
.verification-data-container .verification-table .doc_title,
.verification-data-container .verification-table .transaction_date {
  white-space: nowrap;
}
.verification-table-outer {
  width: calc(100vw - 240px - 256px);
  height: calc(100vh - 200px);
  overflow: auto;
}
.verification-upload-outer {
  width: 240px;
}

.verification-data-foot {
  position: absolute;
  transform: translateX(-50%);
  left: calc(50% - 120px);
  bottom: 0;
}

.verification-data {
  width: 100%;
}

.verification-table {
  /*table-layout: fixed;*/
  width: 100%;
}

.verification-table thead {
  position: sticky;
  top: 0;
  background-color: white;
}

/* Make the first column sticky */
.verification-table th:first-child,
.verification-table td:first-child {
  position: sticky;
  left: 0;
  z-index: 2; /* Ensure the first column remains above other columns */
  background-color: white; /* Background color for the sticky column */
}

.verification-table th {
  font-size: 0.75rem;
  color: #828688;
  font-weight: 500;
  padding: 0;
  white-space: nowrap;
}

.verification-csv-list td > div {
  min-height: 60px;
}

.verification-doc-type {
  width: 12em;
}

.verification-preservation-require {
  width: 12em;
}

.verification-doc-title {
  width: 12em;
}

.verification-received-date {
  width: 12em;
}

.verification-counterparty {
  width: 12em;
}

.verification-amount {
  width: 12em;
}

.verification-currency {
  width: 12em;
}

.verification-invoice {
  width: 12em;
}

.verification-product {
  width: 12em;
}

.verification-reference {
  width: 12em;
}

.verification-shared-folders-path {
  width: 12em;
}

.verification-doc-attributes {
  width: 12em;
}

.verification-pdf-name {
  width: 12em;
}

.verification-download-template {
  background-color: #cfcfd8;
  color: #1a3163;
  border-width: 0;
  border-color: #cfcfd8;
  border-radius: 100px;
  display: flex;
  position: absolute;
  padding: 0 40px;
  top: 20px;
  right: 10px;
  z-index: 100;
}

.verification-file-upload-component {
  width: unset !important;
}

.verification-row-error {
  color: #a71d2a;
}

.row-highlighted {
  background-color: rgb(227, 240, 250);
}

.layout .document-row-list.row-highlighted:hover {
  background-color: rgb(203, 229, 248);
}

.layout .document-row-list.selected.row-highlighted {
  background-color: rgb(178, 213, 238);
}

.layout .document-row-list.selected.row-highlighted:hover {
  background-color: rgb(203, 229, 248);
}

.preview_excel_iframe {
  width: calc(100vw - 320px);
  height: calc(100vh - 243px);
}

.preview_video_iframe {
  width: calc(100vw - 320px);
  height: calc(100vh - 243px);
}

.spinner-icon {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.title-process {
  display: block;
  margin-right: 5px;
}
.process-block {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-weight: 500;
}

.comment-input {
  min-height: 150px !important;
}

@media only screen and (max-width: 767px) {
  .d-sm-block,
  .d-sm-flex {
    display: none !important;
  }
  .documentprepare-container {
    flex-direction: column-reverse;
    justify-content: flex-end !important;
  }
  .documentprepare-container .full-preview .control-preview {
    height: 100vh;
  }
  .layout .layout-right .right-body .file-panel .file-drop-zone {
    padding-top: 15px;
    padding-right: 5px;
    padding-left: 5px;
  }
  .layout .layout-right .right-body .file-panel.upload {
    position: relative;
    top: 0;
    left: 0;
    width: 100% !important;
    overflow: auto;
    margin-top: 0;
    flex-direction: row;
    display: grid;
    grid-template-columns: 40% 1fr;
  }
  .layout .order-last {
    padding-bottom: 120px !important;
  }
  .layout .layout-right .right-body .file-panel .file-upload-list {
    border-left: solid 1px #2c2e3f;
  }
  .layout .layout-right .right-body .confirmation-info .file-panel.preview {
    padding-bottom: 180px;
  }
  .layout .layout-right .right-body .file-panel .file-attachment-list {
    border-top: solid 1px #2c2e3f;
    border-left: solid 1px #2c2e3f;
  }
  .layout .layout-right .right-body .control-container .imprint-stamp {
    max-height: inherit;
  }
  .full-preview .control-preview .pdf-page-container {
    width: 100vw;
  }
  .full-preview canvas {
    max-width: calc(100vw - 40px);
    height: auto !important;
    overflow: hidden;
  }
  .attachment-wrapper {
    max-width: 100vw;
  }

  .accesslog.inactive {
    display: none;
  }
}

.option-dedicated-email{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.option-dedicated-email p {
  margin-bottom: 5px;
  margin-left: 10px;
}
.dedicated-email-input{
  margin-top: 10px;
}
.dedicated-email-input input{
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 5px;
}
