.document-process-title-dots {
    padding: 0 0 0 0 !important;
    color: white !important;
    margin: 0 0 0 0 !important;
}

.document-process-title-dots:hover {
    color: #0a63bc !important;
}

.document-process-item {
    background-color: #131314;
    width: 400px;
}

.document-process-item:hover {
    background-color: #242424;
}

.document-process-title {
    width: 90%;
    font-size: 14px !important;
    line-height: 16px;
}

.document-process-container {
    position: absolute;
    bottom: 0;
    right: 24px;
    z-index: 900;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}

.document-process-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
    padding-left: 16px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    width: 400px;
    height: 52px;
}

.document-process-header-title {

}

.document-process-header-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.document-process-header-icon {
    color: white !important;
}
